<template>
    <div class="index">
        <!-- 心理服务 -->
        <div class="banner">
            <!-- <img src="../../assets/images/service/大厅3@2x.png" alt="">
             -->
             <img v-if="!$store.state.isPhone" :src="$store.state.bannerUrl + $store.state.bannerImgs.xlfw" alt="">
             <img v-else src="../../assets/images/banner/心理服务banner.jpg" alt=""/>
        </div>
        <div class="main">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    created() {
        // this.isPc = sessionStorage.getItem('isPc')
    }
}
</script>

<style lang="scss" scoped>
.index {
    width: 100%;
    height: 100%;
    .banner {
        img {
            height: 300px;
            width: 100%;
        }
    }

    .main {
        display: block;

        // background-color: saddlebrown;
        
    }
}
</style>